/* eslint-disable eqeqeq */
import React, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { Disclosure, Switch } from "@headlessui/react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronUpIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from "@heroicons/react/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import Avatar from "react-avatar-edit";
import Tooltip from "../Small Components/tooltip";
import { CheckIcon } from "@heroicons/react/20/solid";
import "../OperationsDashboard/Operations.css";
import { ClearLocalStorage } from "../ClearLocalStorage";
import Papa from "papaparse";
import {
  CloudArrowDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ListBulletIcon,
  DocumentTextIcon,
  IdentificationIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  UserPlusIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import Tag from "../Tag/Tag";
import { useFormik } from "formik";
import IdCardAll from "../All ID Card/IdCardAll";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Done from "../Alerts/Done";
import NotFound from "../Alerts/NotFound";
import CustomCertificate from "../Certificate/CustomCertificate";
import Skeleton from "react-loading-skeleton";
import ExportCSV from "../OperationsDashboard/ExportCSV";
import { Toaster, toast } from "sonner";
import axiosCacheAdapter from "axios-cache-adapter";
import { ChevronDownIcon } from '@heroicons/react/20/solid'


// import {convertImageUrlToBase64} from "../GlobalImage";
import PrivateImage from '../PrivateImage/PrivateImage';
import { Image } from '@react-pdf/renderer';

const cache = axiosCacheAdapter.setupCache({
  maxAge: 5 * 60 * 1000,
});
const api = axios.create({
  adapter: cache.adapter,
});
const Registration = ({data}) => {
  
  const [detailPersonReg, setDetailPersonReg] = useState(null);
  const [valueOfSelect, setValueOfSelect] = useState("");
  const [flightFilterMissing, setFlightFilterMissing] = useState(false);
  const [flightFilterDate, setFlightFilterDate] = useState(false);
  const [isTshirtSizeMissing, setIsTshirtSizeMissing] = useState(false);
  const [isFoodPrefMissing, setIsFoodPrefMissing] = useState(false);
  const [isFlightArrivalTimeMissing, setIsFlightArrivalTimeMissing] =
    useState(false);
  const [isFlightTicketMissing, setIsFlightTicketMissing] = useState(false);
  const { eventID } = useParams();
  const [onFilters, setOnFilters] = useState(false);
  const [onNtionality, setOnNtionality] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isVisaMissing, setIsVisaMissing] = useState(false);
  const [isPassportMissing, setIsPassportMissing] = useState(false);
  const [isPassportExpiryMissing, setIsPassportExpiryMissing] = useState(false);
  const [isPassportNumberMissing, setIsPassportNumberMissing] = useState(false);
  const [isVisaProcedureMissing, setIsVisaProcedureMissing] = useState(false);
  const [isRejectionDocumentMissing, setIsRejectionDocumentMissing] = useState(false);
  const [isNonAccomodation, setIsNonAccomodation] = useState(false);
  const [apiFilters, setApiFilters] = useState(`["event","=","${eventID}"]`);
  const [allEventRegisterations, setAllEventRegisterations] = useState(null);
  const [allEventSuspended, setAllEventSuspended] = useState([]);
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [open, setOpen] = useState(false);
  const [downloadsPopUp, setDownloadsPopUp] = useState(false);
  const cancelButtonRef = useRef(null)
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Configrations
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  // States
  const [totalRegEntries, setTotalRegEntries] = useState();
  const [nationalitiesToCsv, setNationalitiesToCsv] = useState();
  const [regUpdatedSuccess, setRegUpdatedSuccess] = useState(false);
  const [updateCountrySuccess, setUpdateCountrySuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setpageNumber] = useState([]);
  const tableRef = useRef(null);
  const [page, setPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);

  // Call to get all Event Registerations
  useEffect(() => {
    
    const fetchEventRegisteration = async () => {
      try {
        setLoading(true);
        setIsLoading(true);
        const result = await api.get(
            `https://dash.bestdiplomats.org/api/method/internal-registrations?filters=[${apiFilters}]&limit_start=0&limit_page_length=${page}`,
            config
          )
          .then((response) => {
            let mySet = new Set();
          // console.log("response ",response.data.data);
            setpageNumber([]);
            for (
              let i = 1;
              i <= Math.ceil(response.data.data.length / 30);
              i++
            ) {
              setpageNumber((prevState) => [...prevState, i]);
              // console.log(pageNumbers);
            }
            response.data.data.forEach((person) =>
            mySet.add(person.nationality)
            );
            setAllEventSuspended(Array.from(mySet));
            let arrayOfNationalities = [];
            mySet.forEach((item) =>
              arrayOfNationalities.push({ Country: item })
              );
              setNationalitiesToCsv(arrayOfNationalities);
              // console.log(mySet);
            // console.log(allEventSuspended)

            setTotalRegEntries(response.data.data.length);
            setAllEventRegisterations(response.data.data);
            setLoadingSkeleton(false);
            setIsNotFound(false)
            // if(response.data.data.length==[]){
            //   toast.error('No Data Found')
            //   setLoadingSkeleton(true)
            //   setIsNotFound(true)
            // }
            
            setLoading(false);
          });
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      
        if (e?.code == "ERR_NETWORK" || e?.response?.status == 404) {
          setTotalRegEntries(0);
          toast.error('No Data Found')
          setIsNotFound(true)
          setLoadingSkeleton(true)
        }else{
           setLoadingSkeleton(false)
           toast.error('No Data Found')
           setIsNotFound(true)
           setTotalRegEntries(0);
           setAllEventRegisterations([]);
        }
      }
    };
    fetchEventRegisteration();
  }, [apiFilters, regUpdatedSuccess, updateCountrySuccess, page]);
  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 100);
  };
  const scrollToTop = () => {
    tableRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleClick = (number) => {
    // setCurrentPage(number);
    scroll();
    scrollToTop()
  };
  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handlePaginationClick = (event) => {
    if (event == "Previous") {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
        scroll();
        scrollToTop()
      }
    } else if (event == "Next") {
      if (currentPage < pageNumbers.length) {
        setCurrentPage(currentPage + 1);
        scroll();
        scrollToTop()
      }
    }
  };
// downloadsPopUp
const handleDownloadsPopUp=()=>{
  setDownloadsPopUp(true)
}
const handleDownload = () => {
  setDownloadsPopUp(false)
  const newData = allEventRegisterations?.map((item) => ({
    Event: item.event_name,
    Name: item?.delegate_profile?.user?.full_name,

    Nationality: item?.nationality,
    DateOfBirth: moment(item?.delegate_profile?.birthday).format('LL'),
    ArrivalFlightCode: item?.arrival_flight_code,
    FlightArrivalTime:item?.flight_arrival_time == "" || item?.flight_arrival_time == null
        ? "N/A"
        : item?.flight_arrival_time,
        DepartureFlightCode: item?.departure_flight_code,
    FlightDepartureTime:item?.flight_departure_time == "" || item?.flight_departure_time == null
        ? "N/A"
        : item?.flight_departure_time,
    RegistrationStatus: item?.reg_status,
    RoomStatus: item?.room_status,
    PaymentPackage: item?.payment_package,
    PassportNumber: item?.passport_number,
    PassportExpiry: item?.passport_expiry,
    RepresentingCountry: item?.representing_country,

    Gender: item.gender,
    FoodPreference:
      item?.food_preference == "" ||
      item?.food_preference == null ||
      item?.food_preference == "Not Selected"
        ? "N/A"
        : item?.food_preference,
    TshirtSize:
      item?.tshirt_size == "" ||
      item?.tshirt_size == null ||
      item?.tshirt_size == "Not Selected"
        ? "N/A"
        : item?.tshirt_size,
    VisaScan:
      item?.visa_scan == "" || item?.visa_scan == null
        ? "N/A"
        : "https://dash.bestdiplomats.org" + item?.visa_scan,
  }));

  const csv = Papa.unparse(newData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", "All Downloads.csv");
  document.body.appendChild(link);
  link.click();
};
  // const [csvData, setCsvData] = useState();

  const handleDownloadAccommodation = () => {
    setDownloadsPopUp(false)
    const newData = allEventRegisterations?.map((item) => ({
      Event:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item.event_name:"",
      Name:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ?  item?.delegate_profile?.user?.full_name:"",

      Nationality:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.nationality :"",
      DateOfBirth:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)"? moment(item?.delegate_profile?.birthday).format('LL'):"",
      FlightArrivalTime:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.flight_arrival_time == "" || item?.flight_arrival_time == null ? "N/A" : moment(item?.flight_arrival_time).format("LLL"): "",
      FlightArrivalCode:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.arrival_flight_code == "" || item?.arrival_flight_code== null ? "N/A" :item?.arrival_flight_code: "",
      FlightDepartureTime:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.flight_departure_time == "" || item?.flight_departure_time == null ? "N/A" : moment(item?.flight_departure_time).format("LLL"): "",
      FlightDepartureCode:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.departure_flight_code == "" || item?.departure_flight_code== null ? "N/A" :item?.departure_flight_code: "",
      RegistrationStatus:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.reg_status : "",
      RoomStatus:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.room_status :"",
      PaymentPackage: item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.payment_package:"",
      PassportNumber:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.passport_number:"",
      PassportExpiry:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.passport_expiry: "",
      RepresentingCountry:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.representing_country :"",

      Gender:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item.gender:"",
      FoodPreference: item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.food_preference == "" ||  item?.food_preference == null || item?.food_preference == "Not Selected" ? "N/A" : item?.food_preference :"",
      TshirtSize: item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.tshirt_size == "" ||item?.tshirt_size == null || item?.tshirt_size == "Not Selected"  ? "N/A" : item?.tshirt_size : "" ,
      VisaScan:item?.payment_package=="Accommodation" || item?.payment_package=="Accommodation (Second Payment Pending)"||item?.payment_package=="Accommodation (Second Payment Pending)" || item?.payment_package=="Accommodation (First Payment Pending)" ? item?.visa_scan == "" || item?.visa_scan == null? "N/A": "https://dash.bestdiplomats.org" + item?.visa_scan: "",
    }));

    const csv = Papa.unparse(newData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Only Accommodation.csv");
    document.body.appendChild(link);
    link.click();
  };

  // Formik Configration for Filters

  const applyFilters = useFormik({
    initialValues: {
      diplomat_name_first: "",
      diplomat_name_last: "",
      diplomat_email: "",
      payment_package: "",
      representing_country: "",
      nationality: "",
      t_shirt: "",
      reg_status: "",
      shadow_ban:"",
      missing_visa: "",
      missing_flight_ticket: "",
      flight_time_missing: "",
      flight_date_start: "",
      flight_date_end: "",
    },
    onSubmit: (values) => {
      setOnFilters(false);
      setLoadingSkeleton(true)
      setApiFilters(`["event","=","${eventID}"]`);
      setCurrentPage(1)
      // First Name % addition
      if (values.diplomat_name_first) {
        if (values.diplomat_name_first.length > 1) {
          let diplomat_name_filter_first = "%";
          for (let member in values.diplomat_name_first) {
            diplomat_name_filter_first +=
              values.diplomat_name_first[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState +
              `,["first_name","like","${diplomat_name_filter_first}"]`,
          ]);
        }
      }
      // LAst Name % addition
      if (values.diplomat_name_last) {
        if (values.diplomat_name_last.length > 1) {
          let diplomat_name_filter_first = "%";
          for (let member in values.diplomat_name_last) {
            diplomat_name_filter_first +=
              values.diplomat_name_last[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState + `,["last_name","like","${diplomat_name_filter_first}"]`,
          ]);
        }
      }
      //  Email % Addition
      if (values.diplomat_email) {
        if (values.diplomat_email.length > 1) {
          let diplomat_email_filter = "%";
          for (let member in values.diplomat_email) {
            diplomat_email_filter += values.diplomat_email[member] + "%";
          }
          setApiFilters((prevState) => [
            prevState + `,["delegate_email","like","${diplomat_email_filter}"]`,
          ]);
        }
      }
      //  Payment Package
      if (values.shadow_ban) {
        if (values.shadow_ban.length >= 0) {
          setApiFilters((prevState) => [
            prevState + `,["shadow_ban","=","${values.shadow_ban}"]`,
          ]);
        }
      }  
       if (values.payment_package) {
        if (values.payment_package.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["payment_package","=","${values.payment_package}"]`,
          ]);
        }
      }
      if (values.representing_country) {
        if (values.representing_country.length > 1) {
          setApiFilters((prevState) => [
            prevState +
              `,["representing_country","=","${values.representing_country}"]`,
          ]);
        }
      }
      if (values.nationality) {
        if (values.nationality.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["nationality","=","${values.nationality}"]`,
          ]);
        }
      }

      if (values.t_shirt) {
        if (values.t_shirt.length > 1) {
          if (values.t_shirt == "Filled") {
            setApiFilters((prevState) => [
              prevState + `,["tshirt_size","!=","Not Selected"]`,
            ]);
          } else {
            setApiFilters((prevState) => [
              prevState + `,["tshirt_size","=","${values.t_shirt}"]`,
            ]);
          }
        }
      }
      if (values.food_preference) {
        if (values.food_preference.length > 1) {
          if (values.food_preference == "Filled") {
            setApiFilters((prevState) => [
              prevState + `,["food_preference","!=","Not Selected"]`,
            ]);
          } else {
            setApiFilters((prevState) => [
              prevState +
                `,["food_preference","=","${values.food_preference}"]`,
            ]);
          }
        }
      }
      if (values.reg_status) {
        if (values.reg_status.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["reg_status","=","${values.reg_status}"]`,
          ]);
        }
      }
      if (values.visa_procedure) {
        if (values.visa_procedure.length > 1) {
          setApiFilters((prevState) => [
            prevState + `,["visa_procedure","=","${values.visa_procedure}"]`,
          ]);
        }
      }
      if (values.missing_visa) {
        if (values.missing_visa.length > 1) {
          if (values.missing_visa == "Filled") {
            setApiFilters((prevState) => [
              prevState + `,["visa_scan","!=",""]`,
            ]);
          } else {
            setApiFilters((prevState) => [prevState + `,["visa_scan","=",""],["visa_procedure","!=","Visa On Arrival"],["visa_procedure","!=","Visa Not Needed"]`]);
          }
        }
      }

      if (values.missing_flight_ticket) {
        if (values.missing_flight_ticket.length > 1) {
          if (values.missing_flight_ticket == "Filled") {
            setApiFilters((prevState) => [
              prevState + `,["flight_scan","!=",""]`,
            ]);
          } else {
            setApiFilters((prevState) => [
              prevState + `,["flight_scan","=",""]`,
            ]);
          }
        }
      }

      if (values.flight_time_missing) {
        if (values.flight_time_missing.length > 1) {
          if (values.flight_time_missing == "Filled") {
            setApiFilters((prevState) => [
              prevState + `,["flight_arrival_time","is","set"]`,
            ]);
          }else if(values.flight_time_missing == "Already in destination country"){
            setApiFilters((prevState) => [
              prevState + `,["already_in_destination_country","=","1"]`,
            ]);
          } else {
            setApiFilters((prevState) => [
              prevState + `,["flight_arrival_time","is","not set"],["already_in_destination_country","=","0"]`,
            ]);
          }
        }
      }

      if (values.flight_date_start) {
        if (values.flight_date_start.length > 1) {
          setApiFilters((prevState) => [
            prevState +
              `,["flight_arrival_time",">","${values.flight_date_start}"]`,
          ]);
        }

        if (values.flight_date_end) {
          if (values.flight_date_end.length > 1) {
            setApiFilters((prevState) => [
              prevState +
                `,["flight_arrival_time","<","${values.flight_date_end}"]`,
            ]);
          }
        }
      }
    },

    enableReinitialize: true,
  });
  const [status, setStatus] = useState(null);
  const [statusTremination, setStatusTremination] = useState(false);
  const [statusSuspention, setStatusSuspention] = useState(false);
  const handleViewBox = (e) => {
    // console.log(e);
    setStatus(e);
    if (e == "Terminated") {
      setStatusTremination(true);
      setStatusSuspention(false);
    } else if (e == "Suspended") {
      setStatusTremination(false);
      setStatusSuspention(true);
    } else {
      setStatusTremination(false);
      setStatusSuspention(false);
    }
  };

  // Formik for Registeration Status
  const regStatusUpdate = useFormik({
    initialValues: {
      status_registration: detailPersonReg?.reg_status,
      suspension_reason: detailPersonReg?.suspension_reason,
      termination_reason: detailPersonReg?.termination_reason,
    },
    onSubmit: (values) => {
      var fullUniqueString =
        detailPersonReg.unique_representing_country_checker;
      var SplitUnique = fullUniqueString.split("-");
      var toUpdateUnique = `${SplitUnique[0]}-${SplitUnique[1]}-${status}`;
      if (status == "Suspended") {
        var JSONforUpdate = {
          reg_status: status,
          suspension_reason: values?.suspension_reason,
          unique_representing_country_checker: `${SplitUnique[0]}-${SplitUnique[1]}-Active`,
        };
      }else if(status == "Terminated"){
        var currTimeStamp= new Date().getTime();
        var JSONforUpdate = {
          reg_status: status,
          suspension_reason: values?.suspension_reason,
          unique_representing_country_checker: `${SplitUnique[0]}-${SplitUnique[1]}-Terminated-${currTimeStamp}`,
        };
      } else {
        var JSONforUpdate = {
          reg_status: status,
          termination_reason: values?.termination_reason,

          unique_representing_country_checker: toUpdateUnique,
        };
      }
      const UpdateRegStatus = async () => {
        try {
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Event Registration/${detailPersonReg.name}`,
              JSONforUpdate,
              config
            )
            .then((response) => {
              // console.log(response, JSONforUpdate);
              toast.success("Registration updated successfully");
              setRegUpdatedSuccess(!regUpdatedSuccess);
              setTimeout(() => {
                setRegUpdatedSuccess(false);
              }, 2000);
            });
        } catch (e) {
          // console.log(e);
          toast.error("Registration updated failed");
        }
      };
      UpdateRegStatus();
    },
    enableReinitialize: true,
  });

 
  
const [country, setCountry] = useState(null)
const handleviewCountry=(e)=>{
  setCountry(e)

}
  // Formik for Country Update
   const countryUpdate = useFormik({
     initialValues: {
       representing_country:country
      },
      
      
      onSubmit: (values) => {
      var fullUniqueString =
        detailPersonReg.unique_representing_country_checker;
      var SplitUnique = fullUniqueString.split("-");
      var toUpdateUnique = `${values.representing_country}-${SplitUnique[1]}-${status}`;
      var JSONforUpdate={toUpdateUnique,
      representing_country:country}
           const UpdateCountryStatus = async () => {
        try {
          const result = await axios
            .put(
              `https://dash.bestdiplomats.org/api/resource/Event Registration/${detailPersonReg.name}`,
              JSONforUpdate,
              config
            )
            .then((response) => {
              toast.success("Country updated successfully");
              setUpdateCountrySuccess(!updateCountrySuccess);
              setOpen(false)
              setTimeout(() => {
                setUpdateCountrySuccess(false);
              }, 2000);
            });
        } catch (e) {
          console.log(e);
          toast.error("Country updated failed");
        }
      };
      UpdateCountryStatus();
 
  
    },
    enableReinitialize: true,
  });

 
  //   Call to get single event Registeration

  const fetchEventRegisteration = async (delegateID) => {
    try {
      // setLoading(true);
      const result = await axios
        .get(
          `https://dash.bestdiplomats.org/api/method/get-registration?event=${eventID}&delegate=${delegateID}`,
          config
        )
        .then((response) => {
          // console.log(">>>>>>>>>>>>>>>>>>>>>>", response.data);
          setDetailPersonReg(response.data.data);
          if (
            response.data.data.tshirt_size == null ||
            response.data.data.tshirt_size == ""
          ) {
            setIsTshirtSizeMissing(true);
          }

          if (response.data.data.tshirt_size == "Not Selected") {
            setIsTshirtSizeMissing(true);
          }

          if (
            response.data.data.food_preference == null ||
            response.data.data.food_preference == ""
          ) {
            setIsFoodPrefMissing(true);
          }
          if (response.data.data.food_preference == "Not Selected") {
            setIsFoodPrefMissing(true);
          }
          if (
            response.data.data.flight_arrival_time == null ||
            response.data.data.flight_arrival_time == ""
          ) {
            setIsFlightArrivalTimeMissing(true);
          }
          if (
            response.data.data.flight_scan == null ||
            response.data.data.flight_scan == ""
          ) {
            setIsFlightTicketMissing(true);
          }
          if (
            response.data.data.visa_procedure === "Visa Not Needed" || response.data.data.visa_procedure === "Visa On Arrival"
          ) {
            setIsVisaMissing(false);
          }else
           if (response.data.data.visa_scan == null ||
              response.data.data.visa_scan == "") {
              
                setIsVisaMissing(true);
            }
          
          if (
            response.data.data.delegate_profile.passport_upload == null ||
            response.data.data.delegate_profile.passport_upload == ""
          ) {
            setIsPassportMissing(true);
          }
          if (
            response.data.data.delegate_profile.passport_expiry == null ||
            response.data.data.delegate_profile.passport_expiry == ""
          ) {
            setIsPassportExpiryMissing(true);
          }
          if (
            response.data.data.visa_rejection_document!= null&&
            response.data.data.visa_rejection_document != "" && response.data.data.visa_rejection_document !=undefined &&
            response.data.data.visa_procedure == "Visa Application Rejected"
          ) {
            setIsRejectionDocumentMissing(true);
          } 
          if (
            response.data.data.visa_procedure == null ||
            response.data.data.visa_procedure == "" ||
            response.data.data.visa_procedure == "Not Selected"
          ) {
            setIsVisaProcedureMissing(true);
          }
          if (
            response.data.data.delegate_profile.passport_number == null ||
            response.data.data.delegate_profile.passport_number == ""
          ) {
            setIsPassportNumberMissing(true);
          }
          if (
            response.data.data.payment_package == "Non-Accommodation" ||
            response.data.data.payment_package ==
              "Non-Accommodation (Second Payment Pending)" ||
            response.data.data.payment_package ==
              "Non-Accommodation (First Payment Pending)"
          ) {
            setIsNonAccomodation(true);
          }
          setStatus(response.data.data.reg_status);
          if (response.data.data.reg_status == "Active") {
            setStatusTremination(false);
            setStatusSuspention(false);
          }
          if (response.data.data.reg_status == "Suspended") {
            setStatusTremination(false);
            setStatusSuspention(true);
          }
          if (response.data.data.reg_status == "Terminated") {
            setStatusTremination(true);
            setStatusSuspention(false);
          }
        });
      // setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleViewDetails = async (delegateID) => {
    setIsTshirtSizeMissing(false);
    setIsTshirtSizeMissing(false);
    setIsFoodPrefMissing(false);
    setIsFoodPrefMissing(false);
    setIsFlightArrivalTimeMissing(false);
    setIsFlightTicketMissing(false);
    setIsVisaMissing(false);
    setIsPassportMissing(false);
    setIsPassportExpiryMissing(false);
    setIsPassportNumberMissing(false);
    setIsVisaProcedureMissing(false);
    setIsNonAccomodation(false);
    await fetchEventRegisteration(delegateID);

    setOpen(true);
  };
  const handleFlightFilterType = (e) => {
    setValueOfSelect(e);
    if (e == "missing") {
      setFlightFilterMissing(true);
      setFlightFilterDate(false);
    } else if (e == "date") {
      setFlightFilterDate(true);
      setFlightFilterMissing(false);
    } else {
      setFlightFilterMissing(false);
      setFlightFilterDate(false);
    }
  };
  const [enabledban, setEnabledBan] = useState(false)

 const handleToggleShadowBan=(person)=>{
if(enabledban===true){
  setEnabledBan(false)
  const fetchcategory = async () => {
    var paymentPackeg = {
      shadow_ban: 0
    }
    // console.log("paymentPackeg true",paymentPackeg);
    try {
      await axios
        .put(
          `${window.$domain}/api/resource/Event Registration/${detailPersonReg?.name}`,
          paymentPackeg,
          config
        )
        .then((res) => {
          // console.log("updatae",res);
          toast.success('Shadow ban Disable successfully')
          setUpdateCountrySuccess(!updateCountrySuccess);
        });
    } catch (e) {
      console.log(e);
      toast.error('Failed to Update')
    }
  };

  fetchcategory();
  // console.log("true=",enabledban);
}else{
  setEnabledBan(true)
  const fetchcategory = async () => {
    var paymentPackeg = {
      shadow_ban: 1
    }
    // console.log("paymentPackeg false",paymentPackeg);
    try {
      await axios
        .put(
          `${window.$domain}/api/resource/Event Registration/${detailPersonReg?.name}`,
          paymentPackeg,
          config
        )
        .then((res) => {
          // console.log("updatae",res);
          toast.success('Shadow ban Enable successfully')
          setUpdateCountrySuccess(!updateCountrySuccess);
        });
    } catch (e) {
      console.log(e);
      toast.error('Failed to Update')
    }
  };

  fetchcategory();
  // console.log("false=",enabledban);
}
 }
  // update payment Pakge
  const paymentUpdate = useFormik({
    initialValues: {
      payment_package: detailPersonReg?.payment_package ,
      
 
    },
    onSubmit: (values) => {
      var paymentPackeg = {
          payment_package: values.payment_package,
         
        }
      
     
      const fetchcategory = async () => {
        // console.log("paymentPackeg",paymentPackeg);
        try {
          await axios
            .put(
              `${window.$domain}/api/resource/Event Registration/${detailPersonReg?.name}`,
              paymentPackeg,
              config
            )
            .then((res) => {
              // console.log("updatae",res);
              toast.success('Updated successfully')
              setUpdateCountrySuccess(!updateCountrySuccess);
            });
        } catch (e) {
          console.log(e);
          toast.error('Failed to Update')
        }
      };

      fetchcategory();
    },

    enableReinitialize: true,
  });

  // const [imageSrc, setImageSrc] = useState(null);

  // const imageUrl = 'https://example.com/image.jpg'

  // useEffect(() => {
  //   convertImageUrlToBase64(imageUrl)
  //     .then((base64data) => setImageSrc(base64data))
  //     .catch((error) => console.log(error));
  // }, [imageUrl]);;
  // console.log( ">>>>>",imageSrc);
 
  return (
    <>
      {/* {regUpdatedSuccess && (
        <div className="flex ml-4 shadow-xl bg-green-100 w-96 rounded-lg p-4 mb-4 fixed z-10">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <span className="font-medium text-green-600 flex items-center">
            Registeration Updated Successfully
          </span>
        </div>
      )} */}
      <div className="flex justify-end mt-3 gap-2">
      <Toaster position="bottom-left" richColors />
        {loadingSkeleton ? (
          <>
            <Skeleton width={80} height={40} />{" "}
            <Skeleton width={80} height={40} />{" "}
            <Skeleton width={80} height={40} />{" "}
            <Skeleton width={80} height={40} />
          </>
        ) : (
          <>
            {" "}
            <button
              className="ml-1 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              onClick={() => handleDownloadsPopUp()}
            >
              Download CSV
            </button>
           
            <button
              onClick={() => {
                setOnNtionality(true);
              }}
              className="ml-1 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <span>Nationalities</span>{" "}
            </button>
            <button
              onClick={() => {
                setOnFilters(true);
              }}
              className="ml-1 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              <span>Filter</span>{" "}
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                ></path>
              </svg>
            </button>
            <button
              type="button"
              onClick={() => {
                applyFilters.resetForm();
                setPage(100);
                setApiFilters(`["event","=","${eventID}"]`);
              }}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Clear Filter
            </button>
          </>
        )}
      </div>
      <div className="mt-1 py-1 divide-y ">
        
        <p className="felx justify-end text-right font-semibold text-gray-600">
          {loadingSkeleton ? (
            <Skeleton width={40} height={20} />
          ) : (
            <>Registrations: {totalRegEntries} </>
          )}
        </p>
      </div>
   <div className="px-2">
      <div className="mt-2 flex flex-col max-h-screen">
        <div ref={tableRef} className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-6">
           
         {isNotFound ?<div className="h-screen flex justify-center flex-col items-center ">
                  <svg fill="none" className="w-40 h-40 text-gray-400" stroke="currentColor" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
  <path strokeLinecap="round" strokeLinejoin="round"d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
</svg> <span className=" text-lg text-gray-400">  No Data Found</span> 
                </div> :  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"><table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-stone-100">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-800 sm:pl-6"
                    >
                      User
                    </th>
                    {/* <th
                                    scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                  >
                                    Payment Package
                                  </th> */}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
                    >
                      Visa
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-800"
                    >
                      Representing Country
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 whitespace-nowrap text-left text-sm font-semibold text-gray-800"
                    >
                      Status Registration
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                {loadingSkeleton ? (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    <tr>
                      <td className="whitespace-nowrap py-3 pl-4  text-sm sm:pl-6">
                      <div className="flex items-center">
                          <div
                            className="h-14 w-14 flex-shrink-0"
                           
                          >
                              <Skeleton borderRadius={40} width={50}  height={50}/>
                          </div>
                          <div className="ml-2">
                            <div
                            
                              className="font-medium cursor-pointer text-gray-900"
                            >
                               <Skeleton  width={90}  height={10}/>
                            </div>
                            <div className="text-gray-500">
                            <Skeleton  width={80}  height={10}/>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Skeleton />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          <Skeleton />
                        </div>
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div className="text-gray-900">
                          <Skeleton />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {allEventRegisterations?.map((person) => (
                      <tr key={person.name}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-center">
                            <div className="h-10 w-10 flex-shrink-0"  onClick={() => {
                              // console.log("person",person);
                              setCountry(null)
                              person?.shadow_ban==1?setEnabledBan(true):setEnabledBan(false)
                                  handleViewDetails(
                                    person.delegate_profile.name
                                  );
                                }}>
                              <PrivateImage imageUrl={person.delegate_profile.user.user_image} className={"h-10 w-10 rounded-full cursor-pointer"}/>
                              
                            </div>
                            <div className="ml-4">
                              <div
                                onClick={() => {
                                  setCountry(null)
                                  // console.log("person",person);
                                  person?.shadow_ban==1?setEnabledBan(true):setEnabledBan(false)
                                  handleViewDetails(
                                    person.delegate_profile.name
                                  );
                                }}
                                className="font-medium text-gray-900 cursor-pointer"
                              >
                                {person.delegate_profile.user.first_name +
                                  " " +
                                  person.delegate_profile.user.last_name}
                              </div>
                              <div className="text-gray-500">
                                {localStorage.role == "Operations Team"
                                  ? null
                                  : person.delegate_profile.user.name}
                              </div>
                            </div>
                          </div>
                        </td>
                        
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person?.visa_procedure==="Visa Application Rejected"?person?.visa_rejection_document==null||person?.visa_rejection_document==undefined||person?.visa_rejection_document==""?   <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              Missing
                            </span>:<a
                            href={
                              "https://dash.bestdiplomats.org" +
                              person?.visa_rejection_document
                            }
                            target="_blank"
                            className="flex gap-1"
                          >
                            Open{" "}
                            <svg
                              className="w-5 h-5 ml-1"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                              ></path>
                            </svg>
                          </a>:(person.visa_scan == null || person.visa_scan == undefined||person.visa_scan == "")&&(person.visa_procedure != "Visa Not Needed" && person.visa_procedure != "Visa On Arrival"&& person.visa_procedure != "Not Selected"&&person.visa_procedure != "Visa Applied" && person.visa_procedure != "Need To Apply") ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                            missing

                            </span>
                          ) : (
                            person.visa_procedure === "Visa Applied" ||   person.visa_procedure === "Visa Not Needed" || person.visa_procedure === "Visa On Arrival" ||person.visa_procedure === "Not Selected"||person.visa_procedure === "Need To Apply" ? <>{person.visa_procedure}</>:<>
                              <a
                                href={
                                  "https://dash.bestdiplomats.org" +
                                  person.visa_scan
                                }
                                target="_blank"
                                className="flex gap-1"
                              >
                                Open{" "}
                                <svg
                                  className="w-5 h-5 ml-1"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                  ></path>
                                </svg>
                              </a>
                            </>
                            
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900">
                            {person.representing_country}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {person.reg_status == "Suspended" ? (
                            <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                              {person.reg_status}
                            </span>
                          ) : person.reg_status == "Terminated" ? (
                            <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                              {person.reg_status}
                            </span>
                          ) : (
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {person.reg_status}
                            </span>
                          )}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {/* <button
                                           
                                            className="text-blue-600 hover:text-indigo-900"
                                          >
                                            View Details
                                          </button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table> </div>}
           
          </div>
        </div>
      </div>
   </div>
   {isNotFound? null:totalRegEntries>=100?<div className="flex items-center justify-center mt-7 bg-white px-4 py-3 sm:px-6  rounded-lg shadow">
   {isLoading && <button
        type="button"
        disabled
        className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
       Loading...
      </button>}
       {!isLoading &&(<><button
        type="button"
        onClick={handleLoadMore} disabled={isLoading}
        className="rounded-md banner-back px-3 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
           Load More
      </button><button
        type="button"
        onClick={handleClick} 
        className="rounded-md banner-back px-3 ml-4 py-2 text-sm font-semibold text-white shadow-sm hover:banner-back focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
         <ChevronUpIcon class="h-6 w-6 " />
      </button></>)}
   </div>:""}
   
    
   

      {/* Drawer for Ops Member */}
      {localStorage.role == "Operations Member" ||
      localStorage.role == "Operations Team"|| localStorage.role =="Delegates Department" ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Registration
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                              <div>
                                <div className="-m-1 flex">
                                  <div className="inline-flex overflow-hidden rounded-lg ">
                                  <PrivateImage imageUrl={ detailPersonReg?.delegate_profile.user.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/>
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="mt-6 sm:ml-6 sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      {detailPersonReg?.delegate_profile.user
                                        .first_name +
                                        " " +
                                        detailPersonReg?.delegate_profile.user
                                          .last_name}
                                    </h3>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    {localStorage.role == "Operations Team"
                                      ? null
                                      : detailPersonReg?.delegate_profile.user
                                          .email}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-5 sm:px-0 sm:py-0">
                            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <p>
                                    {detailPersonReg?.delegate_profile.address}
                                  </p>
                                </dd>
                              </div>
                              {localStorage.role == "Operations Team" ? null : (
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Contact Number
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                    {detailPersonReg?.delegate_profile
                                      .contact_number == "" ||
                                    detailPersonReg?.delegate_profile
                                      .contact_number == null ? (
                                      <Tag text="Missing" color="red" />
                                    ) : (
                                      <p>
                                        {
                                          detailPersonReg?.delegate_profile
                                            .contact_number
                                        }
                                      </p>
                                    )}
                                    {}
                                  </dd>
                                </div>
                              )}
                               <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Current Country
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <Tag
                                    text={detailPersonReg?.representing_country}
                                    color="green"
                                  />
                                </dd>
                              </div>
                              <form
                                onSubmit={countryUpdate.handleSubmit}
                                className="flex flex-col divide-y divide-gray-200 gap-2"
                              >
                              <div className="sm:flex flex items-center  sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Select New Country
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <label
                                  htmlFor="representing_country"
                                  className="block sr-only text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                    </label>
                                <select
                                  type="text"
                                  name="representing_country"
                                  id="representing_country"
                                  required
                                  onChange={(e) =>
                                    handleviewCountry(e.target.value)
                                  }
                                 value={country}
                                  className=" block w-52 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                >
                                 <option  value="" >Select</option>
                                 {data?.map((person) => (  
                                   <option  value={`${person}`} key={person}>{person}</option>
                                   ))}

                                  </select>
                               
                                </dd>
                                <button
                                    type="submit"
                                    className="group relative ml-10 flex  w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                              </div>
                                </form>
                                <form
                                onSubmit={paymentUpdate.handleSubmit}
                                className="flex flex-col divide-y divide-gray-200 gap-2"
                              >
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Payment Package
                                </dt>
                                <dd className="mt-1 text-sm  text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {/* <Tag
                                    text={detailPersonReg?.payment_package}
                                    color="green"
                                  /> */}
                                      <label
                                    htmlFor="payment_package"
                                    className=" sr-only"
                                  ></label>
                                  <select
                                    type="text"
                                    name="payment_package"
                                    id="payment_package"
                                    onChange={paymentUpdate.handleChange}
                                    value={paymentUpdate.values.payment_package}
                                    className="mt-1 block w-96 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  >
                                    <option value="Accommodation">Accommodation</option>
                                    <option value="Non-Accommodation">Non-Accommodation</option>
                                    <option value="Accommodation (First Payment Pending)">Accommodation (First Payment Pending)</option>
                                    <option value="Non-Accommodation (First Payment Pending)">Non-Accommodation (First Payment Pending)</option>
                                    <option value="Accommodation (Second Payment Pending)">Accommodation (Second Payment Pending)</option>
                                    <option value="Non-Accommodation (Second Payment Pending)">Non-Accommodation (Second Payment Pending)</option>
                                    <option value="Pay on Arrival">Pay on Arrival</option>
                                    <option value="Full Experience">Full Experience</option>
                                    <option value="Full Experience (Second Payment Pending)">Full Experience (Second Payment Pending)</option>
                                    
                                  </select>
                                  <div className="flex   justify-end "> <button
                                    type="submit"
                                    className="group my-4 ml-10 flex w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button></div>
                                </dd>
                                
                              </div>
                          
                                 
                               
                             
                                  </form>
                                  <div className="sm:flex sm:px-6 sm:py-4">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-60 sm:flex-shrink-0 lg:w-60">
                                  Shadow Ban
                                </dt>
                                  <Switch
      checked={enabledban}
      onChange={() => handleToggleShadowBan(detailPersonReg?.shadow_ban)}
      className={classNames(
        enabledban ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          enabledban ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      >
        <span
          className={classNames(
            enabledban ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            enabledban ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
          )}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
          </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Already in Destination Country
                                </dt>
                                <dd className="mt-1 flex items-center text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <Tag
                                    text={
                                      detailPersonReg?.already_in_destination_country
                                        ? "Yes"
                                        : "No"
                                    }
                                    color="gray"
                                  />
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Flight Arrival Time
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFlightArrivalTimeMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        detailPersonReg?.flight_arrival_time
                                      ).format("LLLL")}
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Flight Departure Time
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {detailPersonReg?.flight_departure_time==="" || detailPersonReg?.flight_departure_time===null ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        detailPersonReg?.flight_departure_time
                                      ).format("LLLL")}
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Flight Ticket
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFlightTicketMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.flight_scan
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  T-Shirt Size
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isTshirtSizeMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    detailPersonReg?.tshirt_size
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Food Preference
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFoodPrefMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    detailPersonReg?.food_preference
                                  )}
                                </dd>
                              </div>
                              {detailPersonReg?.visa_procedure== "Not Selected"||detailPersonReg?.visa_procedure == "Visa Not Needed"?"":detailPersonReg?.visa_application_receipt==""||detailPersonReg?.visa_application_receipt==null||detailPersonReg?.visa_application_receipt==undefined?<div className="sm:flex sm:px-6 sm:py-5"> <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa Application Receipt

                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      <Tag text="Missing" color="red" />
                                   </dd></div>:<div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa Application Receipt

                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <a href={
                                          "https://dash.bestdiplomats.org"+detailPersonReg?.visa_application_receipt
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                
                                </dd>
                              </div>}
                              {/* &&{
                              detailPersonReg?.visa_procedure === "Visa Not Needed" ||  detailPersonReg?.visa_procedure === "Visa On Arrival"||  detailPersonReg?.visa_procedure === "Need To Apply"||detailPersonReg?.visa_procedure === "Not Selected"||detailPersonReg?.visa_procedure === "Need To Apply" ? <>{ detailPersonReg?.visa_procedure}</>:
                             }  */}
                             {detailPersonReg?.visa_procedure != "Visa Application Rejected"&& <>
                         
                              <div className="sm:flex sm:px-6 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Visa Procedure
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {
                              detailPersonReg?.visa_procedure === "Visa Not Needed" ||  detailPersonReg?.visa_procedure === "Visa On Arrival"||  detailPersonReg?.visa_procedure === "Need To Apply"||detailPersonReg?.visa_procedure === "Not Selected"||detailPersonReg?.visa_procedure === "Need To Apply"||detailPersonReg?.visa_procedure === "Visa Applied" ? <>{ detailPersonReg?.visa_procedure}</>:detailPersonReg?.visa_procedure
                             } 
                              </dd>
                            </div>
                            {detailPersonReg?.visa_procedure != "Visa Not Needed" &&detailPersonReg?.visa_procedure != "Visa On Arrival"&&detailPersonReg?.visa_procedure != "Need To Apply"&&detailPersonReg?.visa_procedure != "Visa Applied"&&detailPersonReg?.visa_procedure != "Not Selected"&&detailPersonReg?.visa_procedure != "Need To Apply"&& <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Visa Document
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {
                                    detailPersonReg?.visa_scan==null ||detailPersonReg?.visa_scan==""||detailPersonReg?.visa_scan==undefined?  <Tag text="missing" color="red" />:<>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.visa_scan
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>}
                                </dd>
                              </div>} 
                              </>}
        
                             
                              {detailPersonReg?.visa_procedure === "Visa Application Rejected"&&<>
                                <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa procedure
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {detailPersonReg?.visa_procedure}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                Visa Rejection Document
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                {detailPersonReg?.visa_rejection_document==null||detailPersonReg?.visa_rejection_document==undefined||detailPersonReg?.visa_rejection_document=="" ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                <a href={
                                          "https://dash.bestdiplomats.org"+detailPersonReg?.visa_rejection_document
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>)}
                                
                                </dd>
                              </div> </>}
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Document
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.delegate_profile
                                            .passport_upload
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Expiry
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportExpiryMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    moment(
                                      detailPersonReg?.delegate_profile
                                        .passport_expiry
                                    ).format("D MMMM, YYYY")
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Number
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportNumberMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    detailPersonReg?.delegate_profile.passport_number?.toUpperCase()
                                  )}
                                </dd>
                              </div>
                             
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Diplomatic Stance
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {detailPersonReg?.diplomatic_stance ==""||detailPersonReg?.diplomatic_stance == null ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.diplomatic_stance
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  ID Card
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <Menu as="div" className="relative inline-block text-left  z-40">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
         View And Download ID Card Options
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        enter="transition ease-out duration-100  z-40"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100  z-40"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100  z-40"
        leaveTo="transform opacity-0 scale-95 z-40"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ focus }) => (
                <a
                href={
                  window.$FWDurl +
                  "/IdCard/" +
                  detailPersonReg?.delegate_profile.name +
                  "-" +
                  eventID
                }
                target="_blank"
                  className={classNames(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm hover:bg-gray-200'
                  )}
                >
                   ID Card For Best Diplomats
                </a>
              )}
            </Menu.Item>
             <Menu.Item>
              {({ focus }) => (
                <a
                href={
                  window.$FWDurl +
                  "/IDCard-Impact-Director/" +
                  detailPersonReg?.delegate_profile.name +
                  "-" +
                  eventID
                }
                target="_blank"
                  className={classNames(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm hover:bg-gray-200'
                  )}
                >
                   ID Card For Impact Directors
                </a>
              )}
            </Menu.Item>
          
         
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
                                  {/* <a
                                    href={
                                      window.$FWDurl +
                                      "/IdCard/" +
                                      detailPersonReg?.delegate_profile.name +
                                      "-" +
                                      eventID
                                    }
                                    target="_blank"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    View & Download{" "}
                                    <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                  </a> */}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Invitation Letter
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <a
                                    href={
                                      window.$FWDurl +
                                      "/InvitationValidate/" +
                                      eventID +
                                      "/" +
                                      detailPersonReg?.delegate_profile.user
                                        .email
                                    }
                                    target="_blank"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    View & Download{" "}
                                    <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                  </a>
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Hotel Pass
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isNonAccomodation ? (
                                    <Tag text="Non Accommodation" color="red" />
                                  ) : (
                                    <a
                                      href={
                                        window.$FWDurl +
                                        "/HotelValidate/" +
                                        eventID +
                                        "/" +
                                        detailPersonReg?.delegate_profile.user
                                          .email
                                      }
                                      target="_blank"
                                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                    >
                                      View & Download{" "}
                                      <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                    </a>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Certificate
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-56 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          Certificate Options
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ focus }) => (
                <a
                href={
                  window.$FWDurl +
                  "/Certificate/" +
                  eventID +
                  "/" +
                  detailPersonReg?.delegate_profile.name
                }
                target="_blank"
                  className={classNames(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm hover:bg-gray-200'
                  )}
                >
                  Certificate For Best Diplomats
                </a>
              )}
            </Menu.Item>
             <Menu.Item>
              {({ focus }) => (
                <a
                href={
                  window.$FWDurl +
                  "/Certificate-impact-directors/" +
                  eventID +
                  "/" +
                  detailPersonReg?.delegate_profile.name
                }
                target="_blank"
                  className={classNames(
                    focus ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm hover:bg-gray-200'
                  )}
                >
                 Certificate For Impact Director
                </a>
              )}
            </Menu.Item>
          
         
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
                                  {/* <a
                                    href={
                                      window.$FWDurl +
                                      "/Certificate/" +
                                      eventID +
                                      "/" +
                                      detailPersonReg?.delegate_profile.name
                                    }
                                    target="_blank"
                                    className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    View & Download{" "}
                                    <CloudArrowDownIcon className="w-5 h-5 ml-2" />
                                  </a> */}
                                </dd>
                              </div>
                              <form
                                onSubmit={regStatusUpdate.handleSubmit}
                                className="flex flex-col divide-y divide-gray-200 gap-2"
                              >
                                <div className="sm:flex sm:px-6 sm:py-5">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                    Registration Status
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 flex justify-center items-center gap-3 sm:mt-0 sm:ml-6">
                                    <label
                                      htmlFor="status_registration"
                                      className="sr-only"
                                    ></label>
                                    <select
                                      type="text"
                                      name="status_registration"
                                      id="status_registration"
                                      onChange={(e) =>
                                        handleViewBox(e.target.value)
                                      }
                                      value={status}
                                      required
                                      className="mt-1 block w-52 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    >
                                      <option value="Active">Active</option>
                                      <option value="Suspended">
                                        Suspended
                                      </option>
                                      <option value="Terminated">
                                        Terminated
                                      </option>
                                    </select>
                                  </dd>
                                </div>

                                {statusSuspention ? (
                                  <div className="sm:flex sm:px-6 sm:py-5">
                                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                      Suspension Reason
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      <div className="sm:col-span-2">
                                        <textarea
                                          id="suspension_reason"
                                          name="suspension_reason"
                                          rows={3}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          onChange={
                                            regStatusUpdate.handleChange
                                          }
                                          value={
                                            regStatusUpdate.values
                                              .suspension_reason
                                          }
                                        />
                                      </div>
                                    </dd>
                                  </div>
                                ) : null}
                                {statusTremination ? (
                                  <div className="sm:flex sm:px-6 sm:py-5">
                                    <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                      Termination Reason
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      <div className="sm:col-span-2">
                                        <textarea
                                          id="termination_reason"
                                          name="termination_reason"
                                          rows={3}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          onChange={
                                            regStatusUpdate.handleChange
                                          }
                                          value={
                                            regStatusUpdate.values
                                              .termination_reason
                                          }
                                        />
                                      </div>
                                    </dd>
                                  </div>
                                ) : null}

                                <div className="sm:flex flex justify-end sm:px-6 sm:py-5">
                                  <button
                                    type="submit"
                                    className="group relative flex w-24 justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                  >
                                    Update
                                  </button>
                                </div>
                              </form>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
      {/* Drawer for Hotel Member */}
      {localStorage.role == "Hotel Member" && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                        <div className="px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              Registration
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* Main */}
                        <div className="divide-y divide-gray-200">
                          <div className="pb-6">
                            <div className="h-24  sm:h-20 lg:h-28 bg-blue-500" />
                            <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                              <div>
                                <div className="-m-1 flex">
                                  <div className="inline-flex overflow-hidden rounded-lg ">
                                  <PrivateImage imageUrl={ detailPersonReg?.delegate_profile.user.user_image} className={"h-24 w-24 flex-shrink-0 sm:h-40 sm:w-40 lg:h-48 lg:w-48"}/> 
                                  </div>
                                </div>
                              </div>
                              <div className="mt-6 sm:ml-6 sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                      {detailPersonReg?.delegate_profile.user
                                        .first_name +
                                        " " +
                                        detailPersonReg?.delegate_profile.user
                                          .last_name}
                                    </h3>
                                  </div>
                                  <p className="text-sm text-gray-500">
                                    {
                                      detailPersonReg?.delegate_profile.user
                                        .email
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-5 sm:px-0 sm:py-0">
                            <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  <p>
                                    {detailPersonReg?.delegate_profile.address}
                                  </p>
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Flight Arrival Time
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFlightArrivalTimeMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      {moment(
                                        detailPersonReg?.flight_arrival_time
                                      ).format("LLLL")}
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Flight Ticket
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFlightTicketMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.flight_scan
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>
                                  )}
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Food Preference
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isFoodPrefMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    detailPersonReg?.food_preference
                                  )}
                                </dd>
                              </div>

                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Document
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    <>
                                      <a
                                        href={
                                          "https://dash.bestdiplomats.org" +
                                          detailPersonReg?.delegate_profile
                                            .passport_upload
                                        }
                                        target="_blank"
                                        className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                      >
                                        Open{" "}
                                        <svg
                                          className="w-5 h-5 ml-1"
                                          fill="none"
                                          stroke="currentColor"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                          ></path>
                                        </svg>
                                      </a>
                                    </>
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Expiry
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportExpiryMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    moment(
                                      detailPersonReg?.delegate_profile
                                        .passport_expiry
                                    ).format("D MMMM, YYYY")
                                  )}
                                </dd>
                              </div>
                              <div className="sm:flex sm:px-6 sm:py-5">
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                                  Passport Number
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                  {isPassportNumberMissing ? (
                                    <>
                                      <Tag text="Missing" color="red" />
                                    </>
                                  ) : (
                                    detailPersonReg?.delegate_profile.passport_number.toUpperCase()
                                  )}
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {/* Drawer for Filters */}
      <Transition.Root show={onFilters} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOnFilters}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <form
                      className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
                      onSubmit={applyFilters.handleSubmit}
                    >
                      <div className="flex-1">
                        {/* Header */}
                        <div className="bg-gray-100 px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg flex gap-2 font-medium text-gray-900">
                                <span>Filters</span>{" "}
                                <svg
                                  className="w-6 h-6 mt-1"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                                  ></path>
                                </svg>
                              </Dialog.Title>
                              {/* <p className="text-sm text-gray-500">
                                Get started..
                              </p> */}
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                  setOnFilters(false);
                                }}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Divider container */}

                        <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="diplomat_name_first"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              name="diplomat_name_first"
                              id="diplomat_name_first"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.diplomat_name_first}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="diplomat_name_last"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              name="diplomat_name_last"
                              id="diplomat_name_last"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.diplomat_name_last}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="diplomat_email"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Diplomat Email
                            </label>
                            <input
                              type="text"
                              name="diplomat_email"
                              id="diplomat_email"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.diplomat_email}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            />
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="payment_package"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Payment Package
                            </label>
                            <select
                              type="text"
                              name="payment_package"
                              id="payment_package"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.payment_package}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Accommodation">
                                Accommodation
                              </option>
                              <option value="Accommodation (First Payment Pending)">
                                Accommodation (First Payment Pending)
                              </option>
                              <option value="Accommodation (Second Payment Pending)">
                                Accommodation (Second Payment Pending)
                              </option>
                              <option value="Non-Accommodation">
                                Non-Accommodation
                              </option>
                              <option value="Non-Accommodation (First Payment Pending)">
                                Non-Accommodation (First Payment Pending)
                              </option>
                              <option value="Non-Accommodation (Second Payment Pending)">
                                Non-Accommodation (Second Payment Pending)
                              </option>
                              <option value="Pay on Arrival">
                                Pay on Arrival
                              </option>
                              <option value="Full Experience">
                                Full Experience
                              </option>
                              <option value="Full Experience (Second Payment Pending)">
                                Full Experience (Second Payment Pending)
                              </option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="nationality"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Nationality
                            </label>
                            <select
                              type="text"
                              name="nationality"
                              id="nationality"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.nationality}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                               <option value="">Select</option>
                              <option value="Afghanistan">Afghanistan</option>
                              <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                             
                              <option value="Aruba">Aruba</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bosnia and Herzegovina">
                                Bosnia and Herzegovina
                              </option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="Brunei">Brunei</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Central African Republic">
                                Central African Republic
                              </option>
                              <option value="Chad">Chad</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo, The Democratic Republic of The">
                                Congo, The Democratic Republic of The
                              </option>
                              <option value="Republic of the Congo">
                                Republic of the Congo
                              </option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Côte d'Ivoire">
                                Côte d'Ivoire
                              </option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">
                                Czech Republic
                              </option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">
                                Dominican Republic
                              </option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Eswatini">Eswatini</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">
                                Equatorial Guinea
                              </option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Greece">Greece</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guinea-bissau">
                                Guinea-bissau
                              </option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Holy See (Vatican City State)">
                                Holy See (Vatican City State)
                              </option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="India">India</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="North Korea">North Korea</option>
                              <option value="South Korea">South Korea</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kosovo">Kosovo</option>
                              <option value="Lao People's Democratic Republic">
                                Lao People's Democratic Republic
                              </option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Liechtenstein">
                                Liechtenstein
                              </option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                             
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">
                                Marshall Islands
                              </option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Micronesia, Federated States of">
                                Micronesia, Federated States of
                              </option>
                              <option value="Moldova, Republic of">
                                Moldova, Republic of
                              </option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montenegro">Montenegro</option>
                              <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Namibia">Namibia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherlands">Netherlands</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Norway">Norway</option>
                              <option value="North Macedonia">
                                North Macedonia
                              </option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palestinian Territories">Palestinian Territories</option>
                              <option value="Palau">Palau</option>
                           
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">
                                Papua New Guinea
                              </option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Philippines">Philippines</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="Saint Kitts and Nevis">
                                Saint Kitts and Nevis
                              </option>
                              <option value="Saint Lucia">Saint Lucia</option>
                              <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                              <option value="Samoa">Samoa</option>
                              <option value="State of Libya">State of Libya</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome and Principe">
                                Sao Tome and Principe
                              </option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Serbia">Serbia</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                             
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">
                                Solomon Islands
                              </option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="South Sudan">South Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syrian Arab Republic">
                                Syrian Arab Republic
                              </option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Timor-leste">Timor-leste</option>
                              <option value="Togo">Togo</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad and Tobago">
                                Trinidad and Tobago
                              </option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Uganda">Uganda</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Emirates">
                                United Arab Emirates
                              </option>
                              <option value="United Kingdom">
                                United Kingdom
                              </option>
                              <option value="United States">
                                United States
                              </option>
                              <option value="Uruguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Viet Nam">Viet Nam</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                              <option value="International Observer">
                                International Observer
                              </option>
                              <option value="Ambassador">Ambassador</option>
                              <option value="Diplomatic Experts">
                                Diplomatic Experts
                              </option>
                              <option value="Local Team Members">
                                Local Team Members
                              </option>
                              <option value="Ambassadors">Ambassadors</option>
                              <option value="Speakers and Guest of Honours">
                                Speakers and Guest of Honours
                              </option>
                              <option value="International Observers">
                                International Observers
                              </option>
                              <option value="Volunteers">Volunteers</option>
                              <option value="Johnson and Johnson">Johnson and Johnson</option>
                              <option value="JP Morgan">JP Morgan</option>
                              <option value="HSBC">HSBC</option>
                              <option value="Verizon">Verizon</option>
                              <option value="BRAC">BRAC</option>
                              <option value="International Committee of the Red Cross (ICRC)">International Committee of the Red Cross (ICRC)</option>
                              <option value="Nestle">Nestle</option>
                              <option value="Human Rights Watch">Human Rights Watch</option>
                              <option value="UnitedHealth Group">UnitedHealth Group</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="t_shirt"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              T-Shirt Size
                            </label>
                            <select
                              type="text"
                              name="t_shirt"
                              id="t_shirt"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.t_shirt}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Filled">Filled</option>
                              <option value="Not Selected">Missing</option>
                              <option value="Small (S)">Small (S)</option>
                              <option value="Medium (M)">Medium (M)</option>
                              <option value="Large (L)">Large (L)</option>
                              <option value="Extra Large (XL)">
                                Extra Large (XL)
                              </option>
                              <option value="Double XL (XXL)">
                                Double XL (XXL)
                              </option>
                              <option value="Triple XL (XXXL)">
                                Triple XL (XXXL)
                              </option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="food_preference"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Food Preference
                            </label>
                            <select
                              type="text"
                              name="food_preference"
                              id="food_preference"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.food_preference}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Filled">Filled</option>
                              <option value="Not Selected">Missing</option>
                              <option value="Vegetarian">Vegetarian</option>
                              <option value="Non Vegetarian">
                                Non Vegetarian
                              </option>
                              <option value="Halal">Halal</option>
                              <option value="Sea Food">Sea Food</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="visa_procedure"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Visa Procedure
                            </label>
                            <select
                              type="text"
                              name="visa_procedure"
                              id="visa_procedure"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.visa_procedure}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Not Selected">Not Selected</option>
                              <option value="Visa Document Available">
                                Visa Document Available
                              </option>
                              <option value="Visa Not Needed">
                                Visa Not Needed
                              </option>
                              <option value="Visa On Arrival">
                                Visa On Arrival
                              </option>
                              <option value="Need To Apply">
                                Need To Apply
                              </option>
                              <option value="Visa Applied">Visa Applied</option>
                              <option value="Visa Application Rejected">
                                Visa Application Rejected
                              </option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="reg_status"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Registration Status
                            </label>
                            <select
                              type="text"
                              name="reg_status"
                              id="reg_status"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.reg_status}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Active">Active</option>
                              <option value="Suspended">Suspended</option>
                              <option value="Terminated">Terminated</option>
                            </select>
                          </div>    
                             <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="shadow_ban"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Shadow Ban
                            </label>
                            <select
                              type="text"
                              name="shadow_ban"
                              id="shadow_ban"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.shadow_ban}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="missing_visa"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Visa
                            </label>
                            <select
                              type="text"
                              name="missing_visa"
                              id="missing_visa"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.missing_visa}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Filled">Filled</option>
                              <option value="missing">Missing</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="missing_flight_ticket"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Flight Ticket
                            </label>
                            <select
                              type="text"
                              name="missing_flight_ticket"
                              id="missing_flight_ticket"
                              onChange={applyFilters.handleChange}
                              value={applyFilters.values.missing_flight_ticket}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="Filled">Filled</option>
                              <option value="missing">Missing</option>
                            </select>
                          </div>
                          <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <label
                              htmlFor="flight_arrival_filter_type"
                              className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                            >
                              Flight Arrival Filter Type
                            </label>
                            <select
                              type="text"
                              name="flight_arrival_filter_type"
                              id="flight_arrival_filter_type"
                              onChange={(e) => {
                                handleFlightFilterType(e.target.value);
                              }}
                              value={valueOfSelect}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                            >
                              <option value="">Select</option>
                              <option value="missing">Missing/Filled/Already in destination country</option>
                              <option value="date">By Date</option>
                            </select>
                          </div>
                          {flightFilterMissing ? (
                            <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                              <label
                                htmlFor="flight_time_missing"
                                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                              >
                                Flight Time
                              </label>
                              <select
                                type="text"
                                name="flight_time_missing"
                                id="flight_time_missing"
                                onChange={applyFilters.handleChange}
                                value={applyFilters.values.flight_time_missing}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              >
                                <option value="">Select</option>
                                <option value="Filled">Filled</option>
                                <option value="missing">Missing</option>
                                <option value="Already in destination country">Already in destination country</option>
                              </select>
                            </div>
                          ) : null}
                          {flightFilterDate ? (
                            <>
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <label
                                  htmlFor="flight_date_start"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Flight Time Starts
                                </label>
                                <input
                                  id="flight_date_start"
                                  name="flight_date_start"
                                  type="datetime-local"
                                  onChange={applyFilters.handleChange}
                                  value={applyFilters.values.flight_date_start}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                              <div className="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                                <label
                                  htmlFor="flight_date_end"
                                  className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                                >
                                  Flight Time End
                                </label>
                                <input
                                  id="flight_date_end"
                                  name="flight_date_end"
                                  type="datetime-local"
                                  onChange={applyFilters.handleChange}
                                  value={applyFilters.values.flight_date_end}
                                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>

                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <button
                            type="submit"
                            className="inline-flex color justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Apply Filter
                          </button>
                          <button
                             type="button"
                            onClick={() => {
                              applyFilters.resetForm();
                              setPage(100)
                              setLoadingSkeleton(true)
                              setApiFilters(`["event","=","${eventID}"]`);
                            }}
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          >
                            Clear Filter
                          </button>
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setOnFilters(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* nationalty for Filters */}
      <Transition.Root show={onNtionality} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOnNtionality}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="flex-1">
                        {/* Header */}
                        <div className="banner-back px-4 py-6 sm:px-6">
                          <div className="flex items-start justify-between space-x-3">
                            <div className="space-y-1">
                              <Dialog.Title className="text-lg flex gap-2 font-medium text-white">
                                <span>All Nationalities</span>{" "}
                              </Dialog.Title>
                              {/* <p className="text-sm text-gray-500">
                                Get started..
                              </p> */}
                            </div>
                            <div className="flex h-7 items-center">
                              <button
                                type="button"
                                className="text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                  setOnNtionality(false);
                                }}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="list-decimal ml-6 space-y-1 divide-y text-gray-800  list-inside  dark:text-gray-800">
                      
                           <div className=" py-4 text-sm flex flex-wrap gap-2  px-2 text-gray-500">
                        {allEventSuspended?.map((items, index) => (
                          <span
                            key={index}
                            className="rounded-full border-2 border-gray-200 bg-gray-100 px-3 text-gray-600 text-xs font-semibold leading-5"
                          >
                            {items}
                          </span>
                        ))}
                      </div>
                        </div>
                        {/* <ul className="list-decimal ml-6 space-y-1 divide-y text-gray-800  list-inside  dark:text-gray-800">
                          {allEventSuspended?.map((person) => (
                            <li className="" key={person}>
                              {person}
                            </li>
                          ))}{" "}
                        </ul> */}
                      </div>
                      {/* Action buttons */}
                      <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                        <div className="flex justify-end space-x-3">
                          <div className="ml-3 inline-flex justify-center gap-1 rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                            <ExportCSV
                              data={nationalitiesToCsv}
                              name={"Download CSV"}
                              fileName={`nationality-${eventID}`}
                            />
                          </div>
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => setOnNtionality(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
{/* Downloads csv */}
      <Transition.Root show={downloadsPopUp} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setDownloadsPopUp}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                {/* <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Downloads
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of your data will be permanently removed
                        from our servers forever. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="flex gap-3 justify-between items-center">
                  <button
                    type="button"
                    className="inline-flex w-60 justify-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                    onClick={() => handleDownload()}
                  >
                    Downloads All Csv
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-60 justify-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 "
                    onClick={() => handleDownloadAccommodation()}
                    ref={cancelButtonRef}
                  >
                  Downloads Accommodation
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

    </>
  );
};

export default Registration;
