import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FontRoboto from "../../Roboto-Regular.ttf";
import RobotoBold from "../../Roboto-Bold.ttf";
import RobotoExtraBold from "../../Roboto-Black.ttf";
import axios from "axios";
import fontkit from "@pdf-lib/fontkit";
import QRCode from "qrcode";
import { useSelector } from "react-redux";
import { CheckCircleIcon, PlayCircleIcon } from "@heroicons/react/20/solid";
import BlankLetter from "../../img/invitation_letter_footer.png";
// import BlankLetterTurkiye from "../../img/TurkeytoFill.png";
import BlankLetterlogo from "../../img/Logo-05.png";
import Logo from "../../img/Logo-02.png";
import signature from "../../img/signature.png";
import signatureTurkey from "../../img/signature_discarded.png";
import signatureMalaysia from "../../img/signature_malaysia.png";
import othersignatur from "../../img/othersignatur.png";
import signaturethailand from "../../img/signaturethailandletter.png";
import UK_signature from "../../img/UKSIGNATURE-removebg-preview.png";
import signaturedubai from "../../img/signatureDubaiLetter.png";
import Stamp from "../../img/Stamp-1.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
  PDFDownloadLink,
  usePDF,
} from "@react-pdf/renderer";
import moment from "moment";
import { BellAlertIcon } from "@heroicons/react/24/outline";
import LoadingIcons from "react-loading-icons";

const InvitationValidate = () => {
  var userEmail;
  var JSToken;
  var accessToken;
  if (localStorage.user) {
    userEmail = localStorage.user_email;
    JSToken = JSON.parse(localStorage.user);
    accessToken = JSToken.access_token;
  }
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };

  Font.register({
    family: "Roboto",
    src: FontRoboto,
  });
  Font.register({
    family: "RobotoBold",
    src: RobotoBold,
  });
  Font.register({
    family: "RobotoExtraBold",
    src: RobotoExtraBold,
  });

  const { eventId } = useParams();
  const { username } = useParams();
  const [allData, setAllData] = useState(null);
  const [qrUriImageFile, setqrUriImageFile] = useState(null);
  const [paragraphOne, setparagraphOne] = useState();
  const [paragraphTwo, setparagraphTwo] = useState();
  const [signatureImage, setSignatureImage] = useState();
  const [apiLoading, setApiLoading] = useState(false);
  var first_para;
  const generateQR = async (text) => {
    try {
      setqrUriImageFile(await QRCode.toDataURL(text));
    } catch (err) {
      console.error(err);
    }
  };

  function setParagraphOne(data) {
    var orignal_para = data.invitation_letter.body_paragraph_1;
    var split_event_start_date = orignal_para.split(">EVENT_START");
    var final_paragraph;
    if (split_event_start_date.length > 1) {
      var start_date_added =
        split_event_start_date[0] +
        moment(data.event.start_date).format("D MMMM, YYYY") +
        split_event_start_date[1];
      var split_event_end_date = start_date_added.split(">EVENT_END");

      setparagraphOne(
        split_event_end_date[0] +
          moment(data.event.end_date).format("D MMMM, YYYY") +
          split_event_end_date[1]
      );
      first_para=split_event_end_date[0] +
      moment(data.event.end_date).format("D MMMM, YYYY") +
      split_event_end_date[1]
    } else {
      first_para=orignal_para
      setparagraphOne(orignal_para);
    }
  }

  function setSecondParagraph(data) {
    if (
      data.invitation_letter.body_paragraph_2.split(">DELEGATE_NAME").length > 1
    ) {
      if (data.delegate_profile.gender === "Male") {
        if (
          data.registration.payment_package === "Full Experience" ||
          data.registration.payment_package ===
            "Full Experience (Second Payment Pending)"
        ) {
          if (
            data.invitation_letter.body_paragraph_2_full_experience.split(
              ">EVENT_END_FULL_EXPERIENCE"
            ).length > 1
          ) {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              "Mr. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            var para_after_passport_set =
              passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1];
            var event_end_split = para_after_passport_set.split(">EVENT_END");
            var para_after_event_end_set =
              event_end_split[0] +
              moment(data.event.end_date).format("D MMMM, YYYY") +
              event_end_split[1] +
              ">EVENT_END" +
              event_end_split[2];
            var full_ex_end_split = para_after_event_end_set.split(
              ">EVENT_END_FULL_EXPERIENCE"
            );
            setparagraphTwo(
              full_ex_end_split[0] +
                moment(data.event.full_experience_end_date).format(
                  "D MMMM, YYYY"
                ) +
                full_ex_end_split[1]
            );
          } else {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              "Mr. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            setparagraphTwo(
              passport_number_split[0] +
                data.delegate_profile.passport_number.toUpperCase() +
                passport_number_split[1]
            );
          }
        } else {
          var orignal_para = data.invitation_letter.body_paragraph_2;
          var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
          var para_after_name_set =
            delegate_name_split[0] +
            "Mr. " +
            data.user.first_name +
            " " +
            data.user.last_name +
            delegate_name_split[1];
          var passport_number_split =
            para_after_name_set.split(">PASSPORT_NUMBER");
          setparagraphTwo(
            passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1]
          );
        }
      } else if (data.delegate_profile.marital_status=="Married"&&data.delegate_profile.gender=="Female"){
        if (
          data.registration.payment_package === "Full Experience" ||
          data.registration.payment_package ===
            "Full Experience (Second Payment Pending)"
        ) {
          if (
            data.invitation_letter.body_paragraph_2_full_experience.split(
              ">EVENT_END_FULL_EXPERIENCE"
            ).length > 1
          ) {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0]+
              "Mrs. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            var para_after_passport_set =
              passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1];
            var event_end_split = para_after_passport_set.split(">EVENT_END");
            var para_after_event_end_set =
              event_end_split[0] +
              moment(data.event.end_date).format("D MMMM, YYYY") +
              event_end_split[1] +
              ">EVENT_END" +
              event_end_split[2];
            var full_ex_end_split = para_after_event_end_set.split(
              ">EVENT_END_FULL_EXPERIENCE"
            );
            setparagraphTwo(
              full_ex_end_split[0] +
                moment(data.event.full_experience_end_date).format(
                  "D MMMM, YYYY"
                ) +
                full_ex_end_split[1]
            );
          } else {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              "Mrs. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            setparagraphTwo(
              passport_number_split[0] +
                data.delegate_profile.passport_number.toUpperCase() +
                passport_number_split[1]
            );
          }
        } else {
          var orignal_para = data.invitation_letter.body_paragraph_2;
          var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
          var para_after_name_set =
            delegate_name_split[0] +
            "Mrs. " +
            data.user.first_name +
            " " +
            data.user.last_name +
            delegate_name_split[1];
          var passport_number_split =
            para_after_name_set.split(">PASSPORT_NUMBER");
          setparagraphTwo(
            passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1]
          );
        }
      }else if(data.delegate_profile.marital_status=="Married"&&data.delegate_profile.gender=="Female") {
        if (
          data.registration.payment_package === "Full Experience" ||
          data.registration.payment_package ===
            "Full Experience (Second Payment Pending)"
        ) {
          if (
            data.invitation_letter.body_paragraph_2_full_experience.split(
              ">EVENT_END_FULL_EXPERIENCE"
            ).length > 1
          ) {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0]+
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            var para_after_passport_set =
              passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1];
            var event_end_split = para_after_passport_set.split(">EVENT_END");
            var para_after_event_end_set =
              event_end_split[0] +
              moment(data.event.end_date).format("D MMMM, YYYY") +
              event_end_split[1] +
              ">EVENT_END" +
              event_end_split[2];
            var full_ex_end_split = para_after_event_end_set.split(
              ">EVENT_END_FULL_EXPERIENCE"
            );
            setparagraphTwo(
              full_ex_end_split[0] +
                moment(data.event.full_experience_end_date).format(
                  "D MMMM, YYYY"
                ) +
                full_ex_end_split[1]
            );
          } else {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            setparagraphTwo(
              passport_number_split[0] +
                data.delegate_profile.passport_number.toUpperCase() +
                passport_number_split[1]
            );
          }
        } else {
          var orignal_para = data.invitation_letter.body_paragraph_2;
          var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
          var para_after_name_set =
            delegate_name_split[0] +
            data.user.first_name +
            " " +
            data.user.last_name +
            delegate_name_split[1];
          var passport_number_split =
            para_after_name_set.split(">PASSPORT_NUMBER");
          setparagraphTwo(
            passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1]
          );
        }
      }else {
        if (
          data.registration.payment_package === "Full Experience" ||
          data.registration.payment_package ===
            "Full Experience (Second Payment Pending)"
        ) {
          if (
            data.invitation_letter.body_paragraph_2_full_experience.split(
              ">EVENT_END_FULL_EXPERIENCE"
            ).length > 1
          ) {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              "Miss. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            var para_after_passport_set =
              passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1];
            var event_end_split = para_after_passport_set.split(">EVENT_END");
            var para_after_event_end_set =
              event_end_split[0] +
              moment(data.event.end_date).format("D MMMM, YYYY") +
              event_end_split[1] +
              ">EVENT_END" +
              event_end_split[2];
            var full_ex_end_split = para_after_event_end_set.split(
              ">EVENT_END_FULL_EXPERIENCE"
            );
            setparagraphTwo(
              full_ex_end_split[0] +
                moment(data.event.full_experience_end_date).format(
                  "D MMMM, YYYY"
                ) +
                full_ex_end_split[1]
            );
          } else {
            var orignal_para =
              data.invitation_letter.body_paragraph_2_full_experience;
            var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
            var para_after_name_set =
              delegate_name_split[0] +
              "Miss. " +
              data.user.first_name +
              " " +
              data.user.last_name +
              delegate_name_split[1];
            var passport_number_split =
              para_after_name_set.split(">PASSPORT_NUMBER");
            setparagraphTwo(
              passport_number_split[0] +
                data.delegate_profile.passport_number.toUpperCase() +
                passport_number_split[1]
            );
          }
        } else {
          var orignal_para = data.invitation_letter.body_paragraph_2;
          var delegate_name_split = orignal_para.split(">DELEGATE_NAME");
          var para_after_name_set =
            delegate_name_split[0] +
            "Miss. " +
            data.user.first_name +
            " " +
            data.user.last_name +
            delegate_name_split[1];
          var passport_number_split =
            para_after_name_set.split(">PASSPORT_NUMBER");
          setparagraphTwo(
            passport_number_split[0] +
              data.delegate_profile.passport_number.toUpperCase() +
              passport_number_split[1]
          );
        }
      }

    } else {
      setparagraphTwo(data.invitation_letter.body_paragraph_2);
    }
  }

  function getCorrectSignatureForConference(eventCountry) {
    if (eventCountry == "Thailand") {
      return signaturethailand;
    } else if (eventCountry == "UAE") {
      return signaturedubai;
    } else if (eventCountry == "UK") {
      return UK_signature;
    } else if (eventCountry == "United States") {
      return UK_signature;
    } else if (eventCountry == "Turkiye") {
      return signatureTurkey;
    } else if (eventCountry == "Malaysia") {
      return signatureMalaysia;
    } else {
    // console.log("eventCountry",eventCountry);
      return signature;
    
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      setApiLoading(false);
      try {
        await axios
          .get(
            `https://dash.bestdiplomats.org/api/method/invitation-verification?event=${eventId}&email=${username}`,
            config
          )
          .then((response) => {
            // console.log(response.data.data);
            // console.log(":esposdvov",response.data.data);
            setAllData(response.data.data);
            setParagraphOne(response.data.data);
            setSecondParagraph(response.data.data);
            const getImage = async () => {
              try {

                const result = await axios
                  .get(
                    `https://dash.bestdiplomats.org/api/method/frappe.core.doctype.file.file.download_file?file_url=${response.data.data.invitation_letter.sender_signature}`,
                    {
                      headers: {
                        'Authorization': 'Basic ' + btoa("a2d1a50ce9b0ada" + ':' + "ffff0bfca6dd9d5")
                    },
                    responseType: "blob",
                    }
                  )
                  .then((response) => {
                    var reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onloadend = function () {
                      var base64data = reader.result;
                      setSignatureImage(base64data);
                    };
                  });
              } catch (e) {
                // console.log(">>>>>");
                console.log(e.response);
              }
            };
            getImage();
            setTimeout(() => {
              setApiLoading(false);
            }, 3000);
          });
      } catch (e) {
        console.log(e);
      }
    };
    fetchUserData();
    generateQR(
      window.$FWDurl + "/InvitationValidate/" + eventId + "/" + username
    );
  }, []);

  const styles = StyleSheet.create({
    page: {},
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    viewer: {
      width: 610,
      height: 870,
    },
    bg1: {
      backgroundColor: "#4691dc",
      height: "140px",
      textAlign: "center",
      paddingTop: "10px",
      paddingBottom: "6px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      flexDirection: "column",
    },
    bg2: {
      backgroundColor: "#4691dc",
      height: "8px",
      textAlign: "center",
      marginTop: "6px",
      color: "white",
      display: "flex",
      justifyContent: "center",
      fontSize: "18px",
      flexDirection: "column",
    },

    pictit: {
      fontSize: 17,
      color: "white",
      lineHeight: 1.2,
      marginTop: "5px",
      fontFamily: "RobotoExtraBold",
    },

    toptitle: {
      fontSize: 12,
      color: "white",
      lineHeight: 1.2,
      fontFamily: "Roboto",
    },
    underlinetitle: {
      textAlign: "center",
      textDecoration: "underline",
      top: 15,
      fontSize: 15,
      fontFamily: "RobotoBold",
    },
    QRImage: {
      top: 160,
      left: 470,
      position: "absolute",
      width: 85,
    },
    to: {
      fontFamily: "Roboto",
      left: 40,
      fontSize: 12,
      marginTop: 50,
    },
    visa: {
      fontFamily: "Roboto",
      left: 40,
      fontSize: 12,
      marginTop: 4,
      lineHeight: 1.5,
    },
    salu: {
      left: 40,
      fontSize: 12,
      lineHeight: 1.5,
      fontFamily: "Roboto",
    },
    exellency: {
      fontFamily: "Roboto",
      left: 40,
      fontSize: 12,
      // marginTop: 3,
    },
    firstPara: {
      top: 0,
      fontFamily: "Roboto",
      marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 12,
      marginTop: 8,
      lineHeight: 1.7,
    },
    secondParagraph: {
      top: 5,
      fontFamily: "Roboto",
      marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 12,
      lineHeight: 1.7,
      paddingBottom: "10px",
    },
    ftimg: {
      top: 811.5,
      position: "absolute",
      height: "25px",
      zIndex: -1,
      fontSize: 14,
      marginTop: 2,
      lineHeight: 0,
    },
    reagrds: {
      top: 610,
      position: "absolute",
      left: 40,
      fontFamily: "RobotoBold",
      textAlign: "justify",
      fontSize: 13,
      marginTop: 10,
      lineHeight: 0,
    },
    othrsignatur: {
      top: 640,
      height: "35",
      position: "absolute",
      left: 30,
      color: "red",
      // left: "15",
      width: "140",
    },
    othrsignaturedubai: {
      top: 634,
      position: "absolute",
      left: 34,
      color: "red",
    
      // left: "15",
      width: "124",
      height: "40",
    }, 
    UKsignature: {
      top: 630,
      position: "absolute",
      left: 40,
      color: "red",
      // left: "15",
      width: "110",
      height: "40",
    },

    signatur: {
      top: 640,
      position: "absolute",
      left: 40,
      color: "red",
      left: "15",
      width: "160",
    },
    regnalhd: {
      top: 690,
      position: "absolute",
      left: 40,
      fontFamily: "RobotoBold",
      // marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      // marginTop: 3,
      lineHeight: 0,
    },
    fotname: {
      top: 673,
      position: "absolute",
      left: 40,
      fontFamily: "RobotoBold",
      // marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 13,
      // marginTop: 4,
      lineHeight: 0,
    },
    ftlink: {
      top: 725,
      position: "absolute",
      left: 40,
      color: "#4691dc",
      textDecoration: "underline",
      textAlign: "justify",
      fontSize: 13,

      lineHeight: 0,
    },

    location: {
      top: 708,
      position: "absolute",
      left: 40,

      textAlign: "justify",
      fontSize: 13,

      lineHeight: 0,
    },
    stamp: {
      zIndex: -1,
      opacity: 0.9,
      position: "absolute",
      top: "620",
      left: "170",
      width: "80",
      height: "80",
    },
    secondPara: {
      top: 3,
      fontFamily: "Roboto",
      marginHorizontal: 40,
      textAlign: "justify",
      fontSize: 12,
      lineHeight: 1.7,
      paddingBottom: "10px",
    },
    secondPara1: {
      top: 747,
      position: "absolute",
      left: 107,
      textAlign: "center",
      fontSize: 14,
      marginTop: 9,
      lineHeight: 1.3,
      fontFamily: "RobotoBold",

      // fontSize:"14px"
    },
    secondPara2: {
      top: 770,
      position: "absolute",
      left: 110,
      textAlign: "center",
      fontSize: 14,
      marginTop: 5,
      lineHeight: 1.3,
      fontFamily: "RobotoBold",
    },
    secondPara3: {
      top: 790,
      position: "absolute",
      left: 220,
      textAlign: "center",
      fontSize: 14,
      marginTop: 4,
      lineHeight: 1.3,
      fontFamily: "RobotoBold",
    },
    secondParat1: {
      top: 750,
      position: "absolute",
      left: 75,
      textAlign: "center",
      fontSize: 14,
      marginTop: 9,
      lineHeight: 1.3,
      fontFamily: "RobotoBold",

      // fontSize:"14px"
    },
    secondParat2: {
      top: 770,
      position: "absolute",
      left: 120,
      textAlign: "center",
      fontSize: 14,
      marginTop: 5,
      lineHeight: 1.3,
      fontFamily: "RobotoBold",
    },
  });
  
var myDoc=(<Document>
   <Page size="A4">
   <View style={styles.bg1}>
   <Text>
          <Image
            src={Logo}
            style={{ width: "70px", height: "70px" }}
          ></Image>
        </Text>
        <Text style={styles.pictit}>BEST DIPLOMATS</Text>
        <Text style={styles.toptitle}>
          {"" +
            allData?.invitation_letter?.letterhead_tagline.toUpperCase()}
        </Text>
   </View>
   <View style={styles.bg2}/>
   <Text style={styles.underlinetitle}>
        {"" + allData?.invitation_letter?.underlined_document_title}
      </Text>
      <Image src={qrUriImageFile} style={styles.QRImage} />
      <Text style={styles.to}>To</Text>
            <Text style={styles.visa}>
              {"" + allData?.invitation_letter?.recipient_designation},
            </Text>
            <Text style={styles.salu}>
              {"" + allData?.invitation_letter?.recipient_location}
            </Text>
            <Text style={styles.exellency}>
              {"" + allData?.invitation_letter?.recipient_salutation},
            </Text>
            <Image
              src={BlankLetterlogo}
              style={{
                zIndex: -3,
                opacity: 0.09,
                position: "absolute",
                top: "290",
                left: "40",
                width: "510",
                height: "350",
              }}
            />
            <View style={{ textAlign: "justify" }}>
              <Text style={styles.firstPara}>{"" + paragraphOne}</Text>
              <Text style={styles.secondPara}>{"" + paragraphTwo} </Text>
            </View>
            <Text style={styles.reagrds}>Regards,</Text>
                  <Image
                    src={signatureImage}
                    style={styles.othrsignaturedubai}
                  ></Image>
                  <Image
                    style={styles.stamp}
                    src={Stamp}
                  ></Image>
                  <Text style={styles.regnalhd}>
                    {"" + allData?.invitation_letter?.sender_designation}
                  </Text>
                  <Text style={styles.fotname}>
                    {"" + allData?.invitation_letter?.sender_full_name}
                  </Text>
                  <Text style={styles.ftlink}>{"" + allData?.invitation_letter?.sender_email}</Text>
                  <Text style={styles.location}>
                    {"" + allData?.invitation_letter?.sender_location}
                  </Text>
                  <Text style={styles.secondPara1}>
                    {"" + allData?.invitation_letter?.letterhead_footer_line_1}
                  </Text>
                  <Text style={styles.secondPara2}>
                    {"" + allData?.invitation_letter?.letterhead_footer_line_2}
                  </Text>
                  <Text style={styles.secondPara3}>
                    {"" + allData?.invitation_letter?.letterhead_footer_line_3}
                  </Text>
                  <Image src={BlankLetter} style={styles.ftimg}></Image>
   </Page>
</Document>)

const [instance, updateInstance] = usePDF({ document: myDoc });
// if (instance.loading) return <div>Loading ...</div>;

useEffect(() => {
 updateInstance(myDoc)
}, [signatureImage])
useEffect(() => {
  // console.log(instance);
}, [instance,signatureImage])
  return (
    <div>
      <a href={instance.url} download={`${allData?.user?.first_name}.pdf`}>
      {instance.loading? <span className="roup ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Loading...</span> : <><div className="rounded-md bg-green-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon
                            className="h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-green-800">
                            This is verified document.
                          </h3>
                        </div>
                      </div>
                    </div> <span className="roup ml-auto mr-auto relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"> Download</span></>}
    </a>
      {/* <PDFDownloadLink
        document={
          myDoc
        }
        fileName={`Invitation-${allData?.user?.full_name}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            <span className="group ml-2 relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Loading...
            </span>
          ) : (
            <>
            <div className="rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                This is verified document.
              </h3>
            </div>
          </div>
        </div>
            <span className="group relative flex w-64 justify-center rounded-md border border-transparent bg-blue-400 my-4  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              Download PDF
            </span>
            </>
          )
        }
      </PDFDownloadLink>

      <PDFViewer style={styles.viewer}>
        {myDoc}
      </PDFViewer> */}
    </div>
  );
};

export default InvitationValidate;
